<template>
  <div :class="{ 'active': selected, 'not-dots' :page!=='...' }"
       class="pagination-item"
       @click="updatePage(page)">
    {{ page }}
  </div>
</template>

<script>
export default {
  name: 'PaginationPage',
  props: {
    page: {
      type: [Number, String],
      required: true
    },
    className: {
      type: String,
      required: false,
      default: ''
    },
    selected: {
      type: Boolean,
      default: false
    },
    updatePage: {
      type: Function,
      required: false,
      default: () => {}
    },
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.pagination-item {
  align-items: center;
  display: flex;
  color: var(--blue_light);
  font-size: rem(14);
  height: rem(38);
  justify-content: center;
  line-height: rem(18);
  text-align: center;
  width: rem(34);
  height: rem(34);
  margin: 0 rem(3);

}

.active{
  background-color: var(--purple_light_01);
  border-radius: rem(6);
  color: white;
  font-weight: 500;
}

.not-dots{
  cursor: pointer;

  &:hover {
    background-color: var(--purple_light_01);
    opacity: 60%;
    border-radius: rem(6);
    color: white;
  }
}
</style>
