<template>
  <div class="pagination-button" @click="goToPage(page)">
    <InlineSvg
      :src="icon"
      :class="className"
      class="pagination-arrow"
    />
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'PaginationButton',
  components: {
    InlineSvg
  },
  props: {
    goToPage: {
      type: Function,
      required: true
    },
    page: {
      type: Number,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    className: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.pagination-button {
  margin: 0 rem(2.5);
  display: flex;
  background-color: var(--pink_main);
  border-radius: rem(6);
  width: rem(34);
  height: rem(34);
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    filter: brightness(90%);
  }
  &.inactive {
    cursor: not-allowed;
  }

  &:active {
    cursor: pointer;
    filter: brightness(80%);
  }

  :deep g path {
    fill: white;
  }
}

.pagination-arrow {
  width: rem(13);
  height: rem(13);
}

.pagination-back {
  transform: rotate(180deg);
}

.disabled {
  opacity: 40%;
}

.inactive {
  opacity: 40%;
}
</style>
