<template>
  <div v-if="paginationData.totalPages != 0" class="basic-pagination-wrapper">
    <div class="pagination-box">

      <!-- Go to previous page button -->
      <PaginationButton :icon="require('../../../assets/icons/icn_arrow.svg')"
                        :page="paginationData.currentPage-1"
                        :pressable="paginationData.currentPage >= 2"
                        :class-name="'pagination-back'"
                        :class="{inactive : paginationData.currentPage === 1}"
                        :go-to-page="updatePage"/>

      <PaginationPage v-for="display in pageDisplayList"
                      :key="display"
                      :page="display"
                      :selected="display === paginationData.currentPage "
                      :update-page="updatePage" />

      <!-- Go to next page button -->
      <PaginationButton :icon="require('../../../assets/icons/icn_arrow.svg')"
                        :pressable="paginationData.currentPage >= 2"
                        :page="paginationData.currentPage+1"
                        :class="{inactive : paginationData.currentPage == paginationData.totalPages}"
                        :go-to-page="updatePage"/>
    </div>
  </div>
</template>

<script>
import PaginationPage from './PaginationPage'
import PaginationButton from './PaginationButton'

export default {
  name: 'BasicPagination',
  components: { PaginationButton, PaginationPage },
  props: {
    paginationData: {
      type: Object,
      required: true
    },
    goToPage: {
      type: Function,
      required: true
    }
  },
  computed: {
    //This computes the list of numbers to be displayed on the pagination bar.
    pageDisplayList: function() {
      const total = this.paginationData.totalPages
      const numberToDisplay = Math.min(7, total)

      //special case if only 1 page
      if (numberToDisplay === 1) {
        return [this.paginationData.currentPage]
      }
      const current = this.paginationData.currentPage
      const displayList = [current]

      //work out how many numbers left and right of the
      //current page can be shown
      // e.g. current Page is 2, we show 1 to left, and rest to right

      const valuesEachSide = (numberToDisplay % 2) === 1 ? (numberToDisplay - 1) / 2 : numberToDisplay / 2
      const maxLeft = current - 1
      const maxRight = total - current

      const left = Math.min(maxLeft, valuesEachSide + Math.max(0, valuesEachSide - maxRight))
      const right = Math.min(maxRight, valuesEachSide + Math.max(0, valuesEachSide - maxLeft))

      for (let i = 1; i <= left; i++) {
        displayList.unshift(current - i)
      }
      for (let i = 1; i <= right; i++) {
        displayList.push(current + i)
      }

      //set first and last numbers to 1 and totalPages repsectivly
      displayList[0] = 1
      displayList[displayList.length - 1] = total

      //replace with ... if required
      if (displayList[1] !== 2) {
        displayList[1] = '...'
      }
      if (displayList[displayList.length - 2] !== total - 1) {
        displayList[displayList.length - 2] = '...'
      }
      return displayList
    }
  },
  methods: {
    updatePage: function(page) {
      if (isNaN(page) || page < 1 || page > this.$props.paginationData.totalPages) {
        //Clicked on the "..."
        return
      }
      if (page !== this.$props.paginationData.currentPage) {
        this.$props.goToPage(page)
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.basic-pagination-wrapper {
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin-top: rem(12);
  margin-bottom: rem(12);
}

.pagination-box {
  display: flex;
}
</style>
