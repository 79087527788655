<template>
  <div class="page-title-wrapper">
    <div class="top">
      <h1 class="title">{{ title }}</h1>

      <!-- If another element is needed, e.g. a button, the slot can be used. -->
      <slot name="extra-content"></slot>
    </div>
    <p v-if="subTitle.length > 0" class="sub-title">{{ subTitle }}</p>
  </div>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.page-title-wrapper {
  margin-bottom: 24px;
  .top {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .title {
      font-size: rem(34);
      font-weight: bold;
      letter-spacing: 0;
      line-height: rem(41);
    }
  }
  .sub-title {
    color: var(--purple_light_01);
    font-weight: 500;
  }
}
</style>
