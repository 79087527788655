<template>
  <div class="file-image-preview" :class="[stylePreset]">
    <img
      v-if="validImageMIMEType"
      :src="imagePreview"
      class="file-image-preview__image-preview"
    />
    <video
      v-else-if="validVideoMIMEType"
      ref="video"
      :src="imagePreview"
      class="file-image-preview__image-preview"
      controls
    ></video>
    <img
      v-else
      src="@/assets/icons/icn_file.svg"
      class="file-image-preview__image-preview"
    />
    <p v-if="stylePreset === 'small'" class="file-image-preview__file-name">
      {{ file.name }}
    </p>
    <!-- TODO: Make remove button separate component. -->
    <div class="file-image-preview__remove-button">
      <button @click="removeButtonClicked()">
        <img :src="buttonIcon" class="file-image-preview__remove-button-icon" />
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
export default {
  name: "FilePreview",
  props: {
    file: {
      type: Object,
      required: true,
    },
    emptyImage: {
      type: String,
      require: false,
      default: () => {
        ref(null);
      },
    },
    removeButtonClicked: {
      type: Function,
      required: false,
      default: () => {},
    },
    // The style preset to use. These are based on the invision designs.
    // Currently added;
    // 1. 'big', used for the big file dropboxes in popups (example CMS 2.5.1.1)
    // 2. 'small' used for smaller inputs (example CMS 6.3.2)
    stylePreset: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const validImageTypes = ["image/png", "image/jpeg", "image/gif"];
    const validVideoTypes = ["video/mp4", "video/ogg"];
    const imagePreview = ref(props.emptyImage);

    const validImageMIMEType = computed(() =>
      props.emptyImage
        ? "image/png"
        : validImageTypes.some((type) => props.file.type === type)
    );
    const validVideoMIMEType = computed(() =>
      validImageTypes.some((type) => props.file.type === type)
    );

    const buttonIcon = computed(() =>
      props.stylePreset === "small"
        ? require("@/assets/icons/icn_delete.svg")
        : require("@/assets/icons/btn_removeattachment.svg")
    );

    const fileReader = new FileReader();
    if (!props.emptyImage) {
      fileReader.readAsDataURL(props.file);
      if (validImageTypes) {
        fileReader.onload = imageLoad;
      } else if (validVideoTypes) {
        fileReader.onload = videoLoad;
      }
    }

    function imageLoad() {
      imagePreview.value = fileReader.result;
    }

    function videoLoad() {
      imagePreview.value = fileReader.result;
      this.$refs.video.load();
    }

    return {
      buttonIcon,
      imagePreview,
      validImageMIMEType,
      validVideoMIMEType,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

// Default styling.
.file-image-preview {
  border-radius: rem(8);

  position: relative;

  &__remove-button {
    position: absolute;
    z-index: 1;
    top: rem(64);
    right: rem(12);
    width: rem(32);
    height: rem(32);

    button {
      width: 100%;
      height: 100%;
      background: none;
      border: none;
      padding: 0;
    }
    &-icon {
      width: 100%;
      height: 100%;
    }
    &-icon:hover {
      filter: brightness(0.8);
      cursor: pointer;
    }

    &-icon:active {
      filter: brightness(0.6);
    }
  }
}

// Styling for the big variant.
.file-image-preview.big {
  text-align: center;
  width: rem(600);
  height: rem(386);

  .file-image-preview__image-preview {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 1);
    object-fit: contain;
    border-radius: inherit;
  }

  .file-image-preview__remove-button {
    top: rem(12);
    right: rem(12);
    width: rem(36);
    height: rem(36);
  }
}

// Styling for the small variant.
.file-image-preview.small {
  width: rem(410);
  height: rem(56);
  background-color: #ffffff;
  box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.1);

  display: flex;
  align-items: center;

  .file-image-preview__image-preview {
    width: rem(24);
    height: rem(24);
    object-fit: contain;
    border-radius: rem(4);

    margin-top: rem(16);
    margin-bottom: rem(16);
    margin-left: rem(14);
    margin-right: rem(18);
  }

  .file-image-preview__remove-button {
    top: rem(16);
    width: rem(24);
    height: rem(24);
  }
}
</style>
