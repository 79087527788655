<template>
  <div>
    <!-- Search input -->
    <label class="input-label">
      <!-- TODO: Modify alt texts either with Dutch texts or whatever suits best here.. -->
      <img class="search-icon" src="../../../assets/icons/icn_search.svg" alt="Search icon"/>
      <input :id="text.searchId"
             ref="inputRef"
             v-model="searchedTerm"
             class="search-input"
             :placeholder="text.placeholder"
             @input="setDebounceTimeout"/>
      <inline-svg v-if="searchedTerm && searchedTerm.length > 0"
                  class="clear-search"
                  :src="require('../../../assets/icons/icn_reject.svg')"
                  @click="clearSearch"></inline-svg>
    </label>

  </div>
</template>

<script>
import { ref } from 'vue'
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'Searchbar',
  components: {
    InlineSvg
  },
  props: {
    text: {
      type: Object,
      placeholder: {
        type: String,
        required: true,
      },
      searchId: {
        type: String,
        required: true
      },
      default: () => {
        return {
          placeholder: '',
          searchId: '',
        }
      }
    },
    searchInput: {
      type: Function,
      required: true,
      default: () => {}
    }
  },
  setup(props) {
    const inputRef = ref(null)


    // Search input field
    const searchedTerm = ref('')

    const timeOut = ref(undefined);

    function setDebounceTimeout(data){
      clearTimeout(timeOut.value);
      timeOut.value = setTimeout(handleInput, 500);
    }

    function handleInput(data) {
      if (props.searchInput) {
        props.searchInput(searchedTerm.value)
      }
    }

    function clearSearch(data) {
      searchedTerm.value = ''
      if (props.searchInput) {
        props.searchInput(searchedTerm.value)
      }
    }

    /** Item box **/
    function onItemClick(item) {
    }

    return {
      inputRef,
      // Search input field
      searchedTerm,
      handleInput,
      clearSearch,

      /** Item box **/
      onItemClick,
      setDebounceTimeout
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

$text-color: var(--blue_dark_01);

.input-label {
  position: relative;
}

.input-title {
  color: var(--blue_dark);
  font-size: rem(16);
  font-weight: 500;
  letter-spacing: 0;
  line-height: rem(19);
  margin-bottom: rem(8);
  text-align: left;
}

.search-input {
  border: none;
  border-radius: rem(8);
  background-color: white;
  box-shadow: rem(2) rem(2) 0 0 rgba(0, 0, 0, 0.1);
  color: $text-color;
  font-size: rem(16);
  height: rem(46);
  padding: rem(11) rem(40) rem(11) rem(56);
  //margin-bottom: rem(12);
  min-width: fit-content;
  width: rem(305);
  @include placeholder {
    color: var(--blue_light_01);
    font-family: var(--font-primary);
  }

  &:focus {
    outline: none;
  }
}

.result-indicator {
  font-size: rem(16);
  letter-spacing: 0;
  line-height: rem(22);
  margin-bottom: rem(21);
  margin-top: rem(7);
  opacity: 0.6;
  text-align: left;
}

.clear-search {
  @include position(absolute, $top: rem(1), $right: rem(23));
  cursor: pointer;
  height: rem(16);
  opacity: 0.4;
  width: rem(16);
}

.search-icon {
  @include position(absolute, $top: 50%, $left: rem(16));
  @include hover-active-pointer();
  height: rem(18);
  transform: translateY(-50%);
  width: rem(18);
}
</style>
