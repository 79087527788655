<template>
  <div class="file-dropbox form-element form-group" :class="[stylePreset]">
    <h3 class="form-input-title">
      {{ fieldTitle }}
    </h3>
    <div class="file-dropbox__previews" :class="[stylePreset]">
      <div v-for="(file, index) in trackedFiles" :key="file.name + '-' + index">
        <FilePreview
          :file="file"
          :remove-button-clicked="() => removePreview(index)"
          :style-preset="stylePreset"
        />
      </div>
    </div>
    <div
      v-if="shouldShowDragAndDrop"
      class="file-dropbox__container"
      :class="[{ highlight: isHighlighted, error: errorMessage }, stylePreset]"
      @dragenter="onDragEnter"
      @dragover="onDragOver"
      @dragleave="onDragLeave"
      @drop="onDrop"
      @click="onClickContainer"
    >
      <div class="file-dropbox__container__content">
        <img
          src="../../assets/icons/icn_file.svg"
          class="file-dropbox__container__content__top-image"
        />
        <input
          :id="fileInputId"
          class="file-dropbox__container__content__file-input"
          type="file"
          :multiple="maxAmountOfFiles > 1 ? true : null"
          :accept="allowedMimeTypes.join(',')"
          @change="onChange"
        />
        <p class="file-dropbox__container__content__main-text">
          {{ $t('FILE_DROP_BOX', {mimeTypesMessage: mimeTypesDynamicMessage}) }}
        </p>
        <p class="file-dropbox__container__content__max-size-text">
          ({{ $t('MAXIMUM') }} {{ formatBytes(maxTotalSize) }})
        </p>
      </div>
    </div>

    <!-- The errors of the input. -->
    <div v-show="errorMessage">
      <!-- <p v-for="(error, index) of errorMessages" v-else :key="index" class="text-error">{{ error }}</p>-->
      <p v-if="errorMessage" class="text-error">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { formatBytes } from '@/utils/helpers/ByteFormattingHelper.js'
import { computed } from '@vue/runtime-core'
import { MIME_TYPES } from '@/models/MIMETypes'
import FilePreview from '@/components/elements/FilePreview'

const MB_TO_BYTES_FACTOR = 1048576

export default {
  name: 'FileDropbox',
  components: {
    FilePreview
  },
  props: {
    // The allowed MIME types that can be added in the box.
    // e.g. 'image/jpeg', 'video/mp4'
    allowedMimeTypes: {
      type: Array,
      required: true
    },
    // The style preset to use. These are based on the invision designs.
    // Currently added;
    // 1. 'big', used for the big file dropboxes in popups (example CMS 2.5.1.1)
    // 2. 'small' used for smaller inputs (example CMS 6.3.2)
    stylePreset: {
      type: String,
      required: true
    },
    // The total amount of size for all files in bytes.
    maxTotalSize: {
      type: Number,
      required: false,
      default: 5 * MB_TO_BYTES_FACTOR
    },
    // The maximum size any file can be in bytes.
    maxSizePerFile: {
      type: Number,
      required: false,
      default: 5 * MB_TO_BYTES_FACTOR
    },
    // The maximum number of files that can be added.
    maxAmountOfFiles: {
      type: Number,
      required: false,
      default: 1
    },
    // Name of the field that's displayed aboved the container.
    fieldTitle: {
      type: String,
      required: false,
      default: ''
    },
    // Returns the files that were added by the user.
    callback: {
      type: Function,
      required: false,
      default: () => []
    },
    // Make it possible to give a custom ID to the file input.
    // This will become neccesary to make unique when having several file dropboxes on one page.
    fileInputId: {
      type: String,
      required: false,
      default: 'file-input'
    }
  },
  setup(props) {
    const trackedFiles = ref([])
    const isHighlighted = ref(false)

    const errorMessage = ref()

    const shouldShowImagePreview = computed(() => trackedFiles.value.length > 0)
    const shouldShowDragAndDrop = computed(
      () => trackedFiles.value.length < props.maxAmountOfFiles
    )

    const imagePreview = ref([])

    const reader = new FileReader()
    reader.addEventListener('load', () => {
      imagePreview.value = reader.result
    })

    const mimeTypesDynamicMessage = computed(() => {
      let message = ''

      const moreThanOneMimeTypeUsed = props.allowedMimeTypes.length > 1
      for (let i = 0; i < props.allowedMimeTypes.length; i++) {
        const isLastMimeType = i === (props.allowedMimeTypes.length - 1)
        if (isLastMimeType && moreThanOneMimeTypeUsed) {
          message += ' of '
        }
        message += '.' + MIME_TYPES[props.allowedMimeTypes[i]]

        if (i < props.allowedMimeTypes.length - 2) {
          message += ', '
        }
      }

      return message
    })

    function onDragEnter(e) {
      preventDefaults(e)
      highlight()
    }

    function onDragOver(e) {
      preventDefaults(e)
      highlight()
    }

    function onDragLeave(e) {
      preventDefaults(e)
      unhighlight()
    }

    function onDrop(e) {
      preventDefaults(e)
      unhighlight()
      handleDrop(e)
    }

    function onClickContainer() {
      document.getElementById(props.fileInputId).click()
    }

    function preventDefaults(e) {
      e.preventDefault()
      e.stopPropagation()
    }

    function highlight() {
      isHighlighted.value = true
    }

    function unhighlight() {
      isHighlighted.value = false
    }

    function handleDrop(e) {
      handleFiles(convertFilesToArray(e.dataTransfer.files))
    }

    function convertFilesToArray(files) {
      const filesArr = []
      ;[...files].forEach(file => filesArr.push(file))
      return filesArr
    }

    function handleFiles(files) {
      if (!checkFileValidity(files)) {
        return
      }
      for (let i = 0; i < files.length; i++) {
        const indexOfFile = trackedFiles.value.indexOf(files[i])
        if (indexOfFile === -1) {
          trackedFiles.value.push(files[i])
        }
      }
      props.callback(trackedFiles.value)
      errorMessage.value = ''
    }

    // Checks whether the array of files is valid according to the constraints set by the properties of the component.
    function checkFileValidity(files) {
      if (files.length + trackedFiles.value.length > props.maxAmountOfFiles) {
        errorMessage.value = 'Dit zijn te veel bestanden.'
        return false
      }

      let totalSizeCount = 0
      for (let i = 0; i < trackedFiles.value.length; i++) {
        totalSizeCount += trackedFiles.value[i].size
      }
      for (let i = 0; i < files.length; i++) {
        if (files[i].size > props.maxSizePerFile) {
          errorMessage.value = 'Bestand is te groot.'
          return false
        }
        if (!props.allowedMimeTypes.includes(files[i].type)) {
          errorMessage.value = 'Bestand is niet van het juiste type.'
          return false
        }

        totalSizeCount += files[i].size

        if (totalSizeCount > props.maxTotalSize) {
          errorMessage.value = 'Bestanden zijn te groot.'
          return false
        }
      }

      return true
    }

    function onChange(e) {
      handleFiles(convertFilesToArray(e.target.files))
    }

    function removePreview(index) {
      trackedFiles.value.splice(index, 1)
      props.callback(trackedFiles.value)
    }

    return {
      isHighlighted,
      errorMessage,
      formatBytes,
      mimeTypesDynamicMessage,
      imagePreview,
      trackedFiles,

      shouldShowImagePreview,
      shouldShowDragAndDrop,

      onDragEnter,
      onDragOver,
      onDragLeave,
      onDrop,
      onChange,
      onClickContainer,
      removePreview
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/base.variables';
@import '~@/assets/css/base.mixins';

.file-dropbox.big {
  flex-direction: column;
}

.file-dropbox.small {
  flex-direction: column;
}

.file-dropbox {
  user-select: none;

  display: flex;

  &__previews.big {
    display: flex;
    flex-wrap: wrap;
    gap: rem(16);
  }

  &__previews.small {
    display: flex;
    flex-direction: column;
    gap: rem(8);
    overflow-y: auto;
    overflow-x: hidden;
    max-height: rem(248);
    max-width: rem(700);
    scrollbar-color: var(--blue_dark) #B6C6D7;
    scrollbar-width: thin;
    /* width */
    .file-dropbox__previews::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    .file-dropbox__previews::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    .file-dropbox__previews::-webkit-scrollbar-thumb {
      background: #888;
    }
  }

  &__previews.small > div:last-child {
    margin-bottom: rem(8);
  }
  &__previews.small > div {
    margin-right: rem(24);
  }

  // Default styling file dropbox.
  &__container {
    text-align: center;
    background: #dde1ed;
    outline-color: #a5aec8;
    outline-style: dashed;
    outline-width: rem(1);
    border-radius: rem(8);

    color: #7a95b2;

    position: relative;

    &__content {
      //margin: 0;
      //position: absolute;

      &__top-image {
        width: 5rem;
        height: 5rem;
      }
      &__file-input {
        display: none;
      }
      &__main-text {
        span {
          color: var(--blue_link);
          font-weight: 500; // Medium weight.
          cursor: pointer;
        }
      }
      a {
        font-weight: bold;
      }
    }
  }

  // Styling for the big variant.
  &__container.big {
    width: rem(600);
    height: rem(386);

    .file-dropbox__container__content {
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);

      &__main-text {
        margin-top: rem(31);
        margin-bottom: rem(20);
        width: rem(260);
      }
    }
  }

  // Styling for the small variant.
  &__container.small {
    //width: rem(410);
    //height: rem(110);

    text-align: left;
    .file-dropbox__container__content {
      //top: 0;
      //bottom: 0;

      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: rem(20);

      &__top-image {
        width: rem(42);
        height: rem(50);
        margin-right: rem(22);
      }
      &__main-text {
        width: 50%;
      }
      &__max-size-text {
        margin-left: 12px;
      }
    }
  }
  &__container.small:not(:only-child) {
    margin-top: rem(16);
  }

  &__container.highlight {
    filter: brightness(0.8);
  }
  &__container.error {
    outline-color: var(--red_error);
  }
  &__container:hover {
    cursor: pointer;
  }
}
</style>
