export const MIME_TYPES = {
  // Text
  'text/plain': 'txt',

  // Images
  'image/gif': 'gif',
  'image/jpeg': 'jpg',
  'image/png': 'png',
  'image/avif': 'avif',
  'image/webp': 'webp',

  // Application
  'application/pdf': 'pdf',
  'application/zip': 'zip',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',

  // Audio
  'audio/mpeg': 'mp3',

  // Video
  'video/mp4': 'mp4',
}
