<template>
  <div>
    <PageTitle title="Components To Test" class="page-title"/>
    <p class="page-section-text">Below are the available components and their default displays</p>
    <!-- Contact Section -->
    <h1 class="page-section-title"> toastComponent </h1>
    <Toast v-bind="toast" />

    <h1 class="page-section-title"> Global button </h1>
    <BasicButton v-bind="button" @click="exampleButtonAction(button)" />
    <h1 class="page-section-title"> pagination </h1>
    <BasicPagination :pagination-data="paginationData" :go-to-page="goToPage" />
    <h1 class="page-section-title"> search </h1>
    <Searchbar :text="search" :search-input="searchFunction" />
    <h1 class="page-section-title"> filter toggle with dropdown options </h1>

    <FileDropbox :allowed-mime-types="['image/jpeg', 'image/png', 'video/mp4', 'application/pdf']" :field-title="'Artikelomslag'" :max-amount-of-files="10" :style-preset="'small'" />
    <RichTextField :field-title="'Rich text editor'" />
  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import Toast from '@/components/elements/Toast'
import BasicButton from '@/components/elements/basic/BasicButton'
import BasicPagination from '@/components/partials/pagination/BasicPagination'
import { ref } from '@vue/reactivity'
import Searchbar from '@/components/partials/search/Searchbar'
import FileDropbox from '@/components/elements/FileDropbox'
import RichTextField from '@/components/elements/RichTextEditor/RichTextField'

export default {
  name: 'Help',
  components: {
    PageTitle,
    Toast,
    BasicButton,
    BasicPagination,
    Searchbar,
    FileDropbox,
    RichTextField
  },
  setup() {
    const paginationData = ref({
      currentPage: 4,
      totalPages: 10,
    })

    function goToPage(page) {
      paginationData.value.currentPage = page
    }

    const toast = {
      toastText: 'Example Toast',
      toastType: 'success'
    }
    const button = {
      buttonText: 'Log In'
    }

    const search = {
      placeholder: 'this is the placeholder',
      searchId: '1'
    }

    const dropdownOptions = [
      { id: 1, name: 'this filter 1' },
      { id: 2, name: 'another filter 2' },
      { id: 3, name: 'this can also 3' },
      { id: 4, name: 'finally we have 4' },
      { id: 5, name: 'example 5' },
      { id: 6, name: 'example 6' },
      { id: 7, name: 'example 7' },
      { id: 8, name: 'example 8' },
      { id: 9, name: 'example 9' }]
    const placeholderText = 'Search filters'

    const filterTags = ref(['first tag', 'second tag'])

    function filterTagRemove(filterName) {
      console.log('term removed', filterName)
      const index = filterTags.value.indexOf(filterName)
      if (index > -1) {
        filterTags.value.splice(index, 1)
      }
    }

    function searchFunction(searchTerm) {
      console.log('searched for', searchTerm)
    }

    function exampleButtonAction(pressedButton) {
      console.log('Button Pressed')
    }

    function callbackDropdown(selected) {
      console.log('selected', selected)
    }

    return {
      toast,
      button,
      exampleButtonAction,
      paginationData,
      goToPage,
      search,
      searchFunction,
      filterTags,
      filterTagRemove,

      dropdownOptions,
      placeholderText,
      callbackDropdown
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.section-text {
  margin-bottom: rem(32);
}

.section-title {
  margin-bottom: rem(24);
}
</style>
