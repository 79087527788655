<template>
  <button type="button" class="basic-button" :class="buttonClass" :disabled="disabled">
    <inline-svg v-if="icon" class="basic-button-icon" :src="icon"></inline-svg>
    <p>{{ buttonText }}</p>
  </button>
  <!-- TODO: Add basic button component. -->
</template>

<script>
import InlineSvg from 'vue-inline-svg'


export default {
  name: 'BasicButton',
  components: {
    InlineSvg
  },
  props: {
    buttonText: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: null
    },
    buttonClass: {
      type: String,
      default: 'pink-button'
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.variables";
@import "../../../assets/css/base.mixins";

.basic-button {
  display: flex;
  gap: rem(16);
  justify-content: center;
}

.basic-button-icon{
  min-height: rem(24);
  min-width: rem(24);
  :deep g{
    fill: white;
  }
}

.insight-button {
  background-color: var(--blue_link);
}
</style>
