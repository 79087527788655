<template>
  <div v-if="activeToastData" class="toast-wrapper" :class="toastType" :style="cssVars">
    <div class="toast-wrapper-content">
      <img v-if="activeToastData.src" :src="activeToastData.src" class="toast-icon">
      <p class="toast-text">{{ activeToastData.text }}</p>
    </div>
  </div>
</template>

<script>
import { RESET_TOAST_DATA } from '@/store/mutations'
import { useStore } from 'vuex'
import { computed, watch } from '@vue/runtime-core'

export default {
  name: 'Toast',
  props: {
    icon: {
      type: String,
      required: false,
      default: 'None' // turn into a tick or something similar
    },
    toastType: {
      type: String,
      required: false,
      default: 'success'
    }
  },
  setup() {
    const store = useStore()
    const activeToastData = computed(() => store.getters.getActiveToast)
    const animationTiming = Object.freeze({ animationDuration: 2, animationDelay: 4 })
    const totalAnimationDuration = computed(() => animationTiming.animationDuration + animationTiming.animationDelay)

    const cssVars = computed(() => {
      return {
        '--animation_duration': animationTiming.animationDuration + 's',
        '--animation_delay': animationTiming.animationDelay + 's'
      }
    })

    watch(activeToastData, () => {
      if (activeToastData.value === undefined) {
        return
      }

      setTimeout(() => {
        store.commit(RESET_TOAST_DATA)
      }, totalAnimationDuration.value * 1000)
    })

    return {
      activeToastData,
      cssVars
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.variables";
@import "../../assets/css/base.mixins";

.toast-wrapper{
  text-align: left;
  border-radius: rem(10);
  padding: 0em;
  z-index: 1;
  font-size: 17px;
  width: fit-content;
  animation-name: fadeOutOpacity;
  animation-iteration-count: 1;
  animation-delay: var(--animation_delay);
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  animation-duration: var(--animation_duration);
  position: fixed;
  bottom: rem(58);
  right: rem(48);

  &-content {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: rem(25.5);
    background-color: var(--green_main);
    box-shadow: 2px 2px 0 0 rgba(0,0,0,0.1);
    border-radius: rem(8);
    padding: rem(12) rem(20) rem(12) rem(20);
  }
}

@keyframes fadeOutOpacity {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

.toast-text{
  min-width: rem(200);
  font-size: rem(14);
  font-weight: 800;
  letter-spacing: rem(0.5);
  color: white;
  display: block;  /* may not need this, but I've found I do */
}

.toast-icon {
  width: rem(36);
  height: rem(36);
}

.toast-circle {
  border-radius: 50%;
  padding: -5px -5px;
  background-color: white;
  margin: 4px;
  opacity: 30%;
  margin-top: 8px;
  margin-left: 10px;
}

.success {
  background-color: var(--green_main);
  .icon-icon {
    background-image: 'None';
  }
}

.info {
  background-color: var(--blue_light);
  .icon-icon {
    background-image: 'None';
  }
}

.error {
  background-color: var(--red_error);
  .icon {
    background-image: 'None';
  }
}

</style>
